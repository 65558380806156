<template>
  <v-row justify="center">
    <v-dialog v-model="shower" max-width="700px">
        <!-- <template v-slot:activator="{ on }">
          <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
        </template> -->
        <v-card>        
        <v-layout wrap align-center justify-center v-if="loader === false">
          <v-flex xs12 lg12 class="ma-5">
            <v-card color="white">
              <v-card-text class="pa-0">
                <v-layout
                  row
                  wrap
                  align-center
                  justify-center
                  fill-height
                  class="ma-0"
                >
                  <!-- <v-flex lg6 class="text-center" v-if="resolutionScreen >= 500">
                    <img
                      src="@/assets/landinglogo.png"
                      width="70%"
                      height="70%"
                      alt="One Box"
                    />
                  </v-flex> -->
                  <v-flex lg6 style="background: white" class="pl-3 pr-3">
                    <v-card-text class="text-left">
                      <v-list two-line dense>
                        <v-list-item class="pl-0 pb-0">
                          <v-list-item-content>
                            <h1 style="color: #1976D2" class="text-center">Login</h1>
                            <!-- <h4 style="color: #1976D2; line-height: 40px;">
                              ONE BOX - Storage by your side
                            </h4> -->
                          </v-list-item-content>

                          <!-- <v-list-item-action>
                            <v-menu transition="slide-y-transition" bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn fab text v-on="on" x-small>
                                  <flag :iso="defaultflag" :squared="false" />
                                </v-btn>
                              </template>
                              <v-list nav dense>
                                <v-list-item
                                  v-for="(item, i) in language"
                                  :key="i"
                                  @click="changelang(item.data)"
                                >
                                  <v-list-item-action>
                                    <flag :iso="item.flag" :squared="false" />
                                  </v-list-item-action>
                                  <v-list-item-title>{{
                                    $t(item.name)
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-action> -->
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                    <v-card-text v-if="loginssooneid === false">
                      <b>{{ $t("username") }}</b>
                      <v-text-field
                        class="mt-2"
                        outlined
                        solo
                        hide-details
                        flat
                        color="#174966"
                        prepend-inner-icon="account_box"
                        dense
                        @keyup.enter="login"
                        :disabled="loading"
                        v-model.trim="username"
                      ></v-text-field>
                      <br />
                      <b>{{ $t("password") }}</b>
                      <v-text-field
                        class="mt-2"
                        prepend-inner-icon="lock"
                        outlined
                        solo
                        color="#174966"
                        flat
                        dense
                        hide-details
                        :disabled="loading"
                        v-model.trim="password"
                        @keyup.enter="login"
                        @click:append="showpassword = !showpassword"
                        :type="showpassword ? 'text' : 'password'"
                        :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                      ></v-text-field>
                      <!-- <div class="pt-3 text-right">
                        <a
                          :href="urloneid + '/type_forgot_password'"
                          style="text-decoration: none;"
                          target="_blank"
                          >{{ $t("forgotpassword") }}</a
                        >
                      </div> -->
                      <br/>
                    </v-card-text>
                    <v-card-text class="pt-0" v-if="loginssooneid === false">
                      <v-btn
                        color="primary"
                        block
                        class="elevation-1 white--text"
                        @click="login"
                        :disabled="loading"
                        :loading="loading"
                      >
                        <b>{{ $t("login") }}</b>
                      </v-btn>
                    </v-card-text>
                    <v-card-text v-else>
                      <br><br><br>
                      <v-btn
                        color="primary"
                        block
                        class="elevation-1 white--text"
                        @click="fn_loginoneid"
                        :disabled="loading"
                        :loading="loading"
                      >
                        <b>เข้าสู่ระบบด้วย ONE-ID SSO</b>
                      </v-btn>
                      <br><br><br>
                    </v-card-text>
                    <br />
      
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout row wrap align-center justify-center v-else>
          <v-flex lg4 md5 sm7 xs7>
            <v-progress-linear
              background-color="light-blue lighten-4"
              color="success"
              indeterminate
              rounded
            ></v-progress-linear>
            <loginsso :display="dialogsso" />
          </v-flex>
        </v-layout>

        <div v-if="dialog === true && getcheck === false">
          <v-dialog v-model="dialog" scrollable max-width="400px">
            <v-card>
              <v-card-text style="height: 320px" class>
                <div class="ma-6 pa-0 text-center">
                  <img
                    src="@/assets/notification.png"
                    width="100px"
                    height="100px"
                    alt="One Box"
                  />
                </div>
                <div class="text-center">
                  <h2>เรียนผู้ใช้งาน One Box ทุกท่าน</h2>
                </div>
                <div>
                  <br />
                  <span style="font-size: 16px;">
                    หากท่านเคย upload ข้อมูลไว้ใน One Box กรุณาย้ายข้อมูลของท่านจาก
                    One Box เดิม (
                    <a target="_blank" href="https://archivebox.one.th"
                      >archivebox.one.th</a
                    >) มาที่
                    <a target="_blank" href="https://onebox.evergreen.co.th">box.one.th</a>
                    ภายในวันที่ 30 เมษายน พ.ศ.2563
                  </span>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text class="text-center pa-2">
                <v-checkbox
                  v-model="checkshow"
                  class="mx-2"
                  label="ไม่ต้องการให้แสดงอีก"
                ></v-checkbox>
                <v-btn color="green darken-1" class="white--text" @click="shower = !shower"
                  >รับทราบ</v-btn
                >
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      <!-- </v-app> -->
        </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapGetters, mapState } from "vuex";
import "sweetalert2/src/sweetalert2.scss";
import VueCookies from "vue-cookies";
const loginsso = () => import("../optional/dialog-loginsso.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  props: ["show"],
  components: { loginsso },
  data: function() {
    return {
      defaultflag: 'th',
      loginssooneid: false,
      dialogsso: false,
      ssocode: "",
      intervalSSO: "",
      checkshow: false,
      language: [
        {
          flag: "us",
          name: "languages.english",
          data: "en"
        },
        {
          flag: "th",
          name: "languages.thai",
          data: "th"
        }
      ],
      urloneid: process.env.VUE_APP_ONEID,
      loader: false,
      loading: false,
      username: "",
      password: "",
      showpassword: false,
      dialog: false
    };
  },
  computed: {
    ...mapGetters({
      dataBusinessProfile: "dataBusinessProfile",
      dataAccesstoken: "dataAccesstoken",
      dataLoginDefault:"dataLoginDefault"
    }),
    shower: {
      get() {
        // if (this.show === true) {
         
        // }
        return this.show
      },
      set (value) {
        if (!value) {
          this.$emit('closedialog')
        }
      }
    },
    getcheck() {
      let check = localStorage.getItem("show");
      if (check === "true") {
        return true;
      } else {
        return false;
      }
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    }
  },
  methods: {
    login() {
      this.loading = true;
      let payload = {
        username: this.username,
        password: this.password
      };
      // this.$router.push('/mydrive')
      this.$store
        .dispatch("authorize_login", payload)
        .then(msg => {
          // console.log(msg);
          this.loading = false;
          if (msg.status === "Access Granted") { 
            // ส่ง event ไปยัง Google Analytics
            this.$gtag.event('login', {
              event_category: "username",
            });

            // console.log("this.dataLoginDefault",this.dataLoginDefault,this.dataLoginDefault["account_category"]); 
            if(this.dataLoginDefault !== "") {
              console.log("เข้า if"); 
              if(this.dataLoginDefault["account_category"] === "Business"){
                console.log(this.dataLoginDefault["account_category"]);
                this.loginbusiness_default();
              }
            }
            this.$emit("login_success")
          } else if (msg.code === "LN001") {
            Toast.fire({
              icon: "error",
              title: "Username หรือ Password ไม่ถูกต้อง"
            });
          } else if (msg.code === "ER401") {
            Toast.fire({
              icon: "error",
              title: "Username หรือ Password ไม่ถูกต้อง"
            });
          } else if (msg.msg === "ER401: Authorization is wrong") {
            Toast.fire({
              icon: "error",
              title: "Username หรือ Password ไม่ถูกต้อง"
            });
            this.loader = false;
          } else {            
            Toast.fire({ icon: "error", title: msg.msg });
          }        
        })
        .catch(err => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ "
          });
          this.loading = false;
        });
    },
    changelang(parameter) {
      localStorage.setItem("lang", parameter);
      this.$i18n.locale = parameter;
      let dflag = this.language.findIndex(v => v.data === parameter);
      this.defaultflag = this.language[dflag]["flag"];
    },
    async loginsso(code) {
      let payload = {
        token: code,
      };
      this.loader = true;
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_SSO + '/api/login_by_token_oneid',
          payload
        )
        .then((res) => {
          if (res.data.status == 'OK') {
            localStorage.setItem('token', res.data.result['accesstoken']); // ไม่ได้ใช้งาน
            VueCookies.set('token', res.data.result['accesstoken']);
            VueCookies.set('token_auth', true);
            this.$store.dispatch('authorize_granted', res.data.result);
            if (this.dataLoginDefault !== '') {
              if (this.dataLoginDefault['account_category'] === 'Business') {
                console.log(this.dataLoginDefault['account_category']);
                this.loginbusiness_default();
              }
            } else {
              // this.$cookies.remove('ssocode')
              this.$router.push('/mydrive');
            }
          } else {
            this.$store.dispatch('authorize_denied');
            // this.$cookies.remove('ssocode')
            this.loader = false;
            // this.dialogsso = true
            // this.intervalSSO = setInterval(() => { this.ssocode = this.$cookies.get('ssocode') }, 800);
            Toast.fire({
              icon: 'error',
              title: 'ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ ',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: 'error',
            title: 'ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ ',
          });
          this.loading = false;
        });
    },
    loginbusiness_default(){
      let payload = {
        taxid: this.dataLoginDefault.id_card_num,
        accesstoken: this.dataAccesstoken,
        biz_id:this.dataLoginDefault.id        
      };
      this.$store.dispatch("switchaccount_business", payload).then((msg) => {
      if (msg["status"] === "Business Access Denied") {        
        console.log("msg", msg);
        if (msg["msg"] === "ER401: Authorization is wrong") {
          this.$router.push("logout");
        } else {
          // this.$store.dispatch("switchaccount_citizen").then(msg => {
          //   this.$emit("loadfile");
          //   this.$emit("callstorage");
          //   this.$router.push({ name: "myfiles" });
          // });
        }
      } else {
        this.$router.push(this.$route.query.nextUrl);
        // this.$router.push({ path: "/directory/" + this.dataLoginDefault["my_folder_id"] });
        //this.$router.push({ name: "myfiles" });
      }
    });
    },
    loginbusiness(path, taxid) {
      this.loading = true;
      let payload = {
        taxid: taxid,
        accesstoken: this.dataAccesstoken
      };
      this.$store.dispatch("switchaccount_business", payload).then(msg => {
        if (msg["msg"] === "ER401: Authorization is wrong") {
          console.log(msg);
          this.loader = false;
        } else {
          const pobj = JSON.stringify(payload);
          //sessionStorage.removeItem(pobj);
          sessionStorage.setItem("biz_active", pobj);
          if (path !== null || path !== undefined || path === "") {
            this.$router.push({
              name: path,
              query: {
                type: 2,
                sourcesystem: "ETAX",
                systemid: this.$route.query.systemid,
                fromdate: this.$route.query.crefromdate,
                todate: this.$route.query.crefromdate,
                fromdatedoc: this.$route.query.docfromdate,
                todatedoc: this.$route.query.doctodate,
                doctype: this.$route.query.doctype,
                doc_no_start: this.$route.query.docnostart,
                doc_no_end: this.$route.query.docnoend,
                filename: this.$route.query.filename,
                fileextension: this.$route.query.filetype,
                sendername: this.$route.query.sellername,
                sellerBranchid: this.$route.query.sellerbranchid,
                sellerTaxid: this.$route.query.sellertaxid,
                receiver_name: this.$route.query.buyername,
                receiverTaxid: this.$route.query.buyertaxid,
                receiverBranchid: this.$route.query.buyerbranchid
              }
            });
          } else {
            this.$router.push({ name: "myfiles" });
          }
        }
      });
    },
    fn_loginoneid() {
      location.assign(process.env.VUE_APP_ONEIDSSO);
    }
  },
  mounted() {
    let _lang = localStorage.getItem("lang") || "th"
    let idxLang = this.language.findIndex(v => v.data === _lang);
    this.defaultflag = this.language[idxLang]["flag"];
  }
};
</script>

<style></style>